import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker, Range } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import BigDriverIcon from "../../Icons/BigDriverIcon";
import BigLocationIcon from "../../Icons/BigLocationIcon";
import CalenderIcon from "../../Icons/CalenderIcon";
import ChargerIcon from "../../Icons/ChargerIcon";
import RefreshIcon from "../../Icons/RefreshIcon";
import SessionIcon from "../../Icons/SessionIcon";
import DataBox from "../CommonSection/DataBox/DataBox";
import Dropdown from "../CommonSection/Dropdown/Dropdown";
import classes from "./Dashboard.module.scss";
import EnergyUsage from "./EnergyUsage/EnergyUsage";
import Feedback from "./Feedback/Feedback";
import FleetAprrovedRequest from "./FleetAprrovedRequest/FleetAprrovedRequest";
import RevenueOfSubscriptions from "./RevenueOfSubscriptions/RevenueOfSubscriptions";

const Dashboard = () => {
  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showRange, setShowRange] = useState(false);

  const fleet = [
    { value: 1, label: "fleet 1" },
    { value: 2, label: "fleet 2" },
    { value: 3, label: "fleet 3" },
    { value: 4, label: "fleet 4" },
    { value: 5, label: "fleet 5" },
  ];

  const dataBoxData = [
    {
      svg: <BigLocationIcon />,
      label: "Total Stations",
      value: "325",
    },
    {
      svg: <ChargerIcon />,
      label: "Total Chargers",
      value: "425",
    },
    {
      svg: <BigDriverIcon />,
      label: "Total No. of Drivers",
      value: "5487",
    },
    {
      svg: <SessionIcon />,
      label: "Total Sessions ",
      value: "650",
    },
  ];

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.dashboard_col1}>
        <div className={`${classes.dashboard_dashboardFilter_container}`}>
          <div className={`${classes.dashboard_dashboardFilter_separates}`}>
            <div>
              <Dropdown
                id="fleet"
                name="fleet"
                data={fleet}
                label="Fleet"
                placeholder="All Fleets"
                className={`${classes.dashboardFilter_select}`}
              />
            </div>
            <div className={`${classes.dashboard_dashboardFilter_details}`}>
              <div className="relative">
                <div
                  className="dashboard_cal_icon"
                  role="button"
                  aria-hidden="true"
                  onClick={() => setShowRange(true)}
                >
                  <CalenderIcon />
                </div>
                {showRange && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowRange(false);
                    }}
                  >
                    <div className="absolute mt-2 left-[-3rem] lg:left-[-6rem] xl:left-0 h-fit w-fit shadow-lg z-[1]">
                      <DateRangePicker
                        onChange={(item) => setRanges([item.selection])}
                        months={1}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        ranges={ranges}
                      />
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
              <div
                className={`${classes.dashboard_dashboardFilter_update} md:hidden xl:block`}
              >
                <p>Last Updated on September 27th, 2022, 03:17 P.M</p>
              </div>
              <div className={`${classes.refreshIcon}`}>
                <RefreshIcon />
              </div>
            </div>
          </div>
          <div
            className={`${classes.dashboard_dashboardFilter_update} md:block xl:hidden`}
          >
            <p>Last Updated on September 27th, 2022, 03:17 P.M</p>
          </div>
        </div>

        <div className={classes.dashboard_info_cards}>
          <div className={classes.dashboard_totalfleet_card}>
            <span className="text-mediumtext 2xl:text-subtitle2">
              Total Fleet
            </span>
            <span className="font-semibold text-h4">3,207</span>
          </div>

          <div className={classes.dashboard_carbonfootprint_card}>
            <span className={classes.dashboard_carbonfootprint_title}>
              Carbon footprint reduction
            </span>
            <div className={classes.dashboard_carbonfootprint_detail}>
              <div
                className={`${classes.dashboard_carbonfootprint_data} border-r-1`}
              >
                <span className="font-medium text-body1">Co2 Saved</span>
                <span className="font-normal text-h4">
                  34 <span className="text-smalltext">kg</span>
                </span>
              </div>

              <div className={classes.dashboard_carbonfootprint_data}>
                <span className="font-medium text-body1">Fuel Replaced</span>
                <span className="font-normal text-h4">2547</span>
              </div>
            </div>
            <div className={classes.dashboard_carbonfootprint_thankyou}>
              <span>1.735 kWh charged this month.</span>
              <span>Thanks for your contributation to a greener feature.</span>
            </div>
          </div>
        </div>

        <div className={classes.dashboard_datboxes}>
          {dataBoxData.map((args, i) => (
            <DataBox key={i} {...args} />
          ))}
        </div>

        <div className={classes.dashboarddata_cards}>
          <div className="flex flex-col">
            <span className={classes.dashboardcard_title}>Energy Usage</span>
            <div className={classes.dashboardcard_component1}>
              <EnergyUsage />
            </div>
          </div>

          <div className="flex flex-col">
            <span className={classes.dashboardcard_title}>
              Revenue of Subscriptions
            </span>
            <div className={classes.dashboardcard_component1}>
              <RevenueOfSubscriptions />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.dashboard_col2}>
        <div className={classes.dashboard_fleetaprrovedrequest}>
          <FleetAprrovedRequest />
        </div>
        <div className={classes.dashboard_feedback}>
          <Feedback />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
