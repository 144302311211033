import type { NextPage } from "next";
import Dashboard from "../components/Dashboard/Dashboard";
import Layout from "../layout/Layout";

const Home: NextPage = () => (
  <div>
    <Layout pageName="Dashboard">
      <Dashboard />
    </Layout>
  </div>
);

export default Home;
