import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

export interface DropdownPropsTypes {
  id: string;
  name?: string;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  label?: string;
  data: { value: string | number; label: string }[];
  // defaultValue?: SingleValue<{ value: string | number; label: string }>;
  // onChange?: (
  //   newValue?: SingleValue<{ value: string | number; label: string }> | MultiValue<{ value: string | number; label: string }>,
  //   actionMeta?: ActionMeta<{ value: string | number; label: string }>,
  // ) => void;
}

const Dropdown: React.FC<DropdownPropsTypes> = (props): JSX.Element => {
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  };

  return (
    <FormControl className="w-full">
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        labelId="demo-simple-select-label"
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        value={age}
        label={props.label}
        onChange={handleChange}
        className={props.className}
        disabled={props.isDisabled}
      >
        {props.data.map((data, i) => (
          <MenuItem key={i} value={data.value}>
            {data.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  className: undefined,
  label: undefined,
  name: undefined,
  placeholder: undefined,
  isDisabled: undefined,
};

export default Dropdown;
